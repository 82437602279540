<template>
  <div class="flex flex-col min-h-screen">
    <main-navigation />
    <div
      v-if="showBannerMessage"
      class="bg-white shadow flex justify-center items-center"
    >
      <p class="text-gray-800 m-4">
        <!-- Banner message -->
      </p>
      <button
        @click="markBannerAsRead"
        class="rounded-full w-6 h-6 mx-4 ml-0 text-white bg-orange-400 hover:bg-orange-500 font-bold"
      >
        &times;
      </button>
    </div>
    <div class="flex-1 container mx-auto text-gray-700">
      <nuxt />
    </div>
    <site-footer />
    <notifications classes="toast-notification" position="top center" />
  </div>
</template>

<script>
import SiteFooter from '~/components/SiteFooter.vue'
import MainNavigation from '~/components/MainNavigation.vue'
const siteBannerCookie = 'ifl_bannermessage'
const siteBannerValue = 'giftcards20241212'

export default {
  components: {
    SiteFooter,
    MainNavigation
  },
  data() {
    return {
      showBannerMessage: false // this.$jsCookies.get(siteBannerCookie) !== siteBannerValue
    }
  },
  methods: {
    markBannerAsRead() {
      this.$jsCookies.set(siteBannerCookie, siteBannerValue, { expires: 7 })
      this.showBannerMessage = false
    }
  }
}
</script>
