import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _76629232 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _63f41695 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _ff1fb6f4 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _162a8a50 = () => interopDefault(import('../pages/gift-cards/index.vue' /* webpackChunkName: "pages/gift-cards/index" */))
const _054e537a = () => interopDefault(import('../pages/impersonate.vue' /* webpackChunkName: "pages/impersonate" */))
const _937a61ac = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1d1625f7 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _2bf8832a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _b209fd1c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _60846830 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _2c9ecef2 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _99d3a3d2 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _28f698f4 = () => interopDefault(import('../pages/gift-cards/redeem.vue' /* webpackChunkName: "pages/gift-cards/redeem" */))
const _4852d0c2 = () => interopDefault(import('../pages/gift-cards/thank-you.vue' /* webpackChunkName: "pages/gift-cards/thank-you" */))
const _55190dcc = () => interopDefault(import('../pages/cart/_id.vue' /* webpackChunkName: "pages/cart/_id" */))
const _35d98bc2 = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _26c9a7da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _76629232,
    name: "cart"
  }, {
    path: "/checkout",
    component: _63f41695,
    name: "checkout"
  }, {
    path: "/forgot-password",
    component: _ff1fb6f4,
    name: "forgot-password"
  }, {
    path: "/gift-cards",
    component: _162a8a50,
    name: "gift-cards"
  }, {
    path: "/impersonate",
    component: _054e537a,
    name: "impersonate"
  }, {
    path: "/login",
    component: _937a61ac,
    name: "login"
  }, {
    path: "/orders",
    component: _1d1625f7,
    name: "orders"
  }, {
    path: "/profile",
    component: _2bf8832a,
    name: "profile"
  }, {
    path: "/register",
    component: _b209fd1c,
    name: "register"
  }, {
    path: "/reset-password",
    component: _60846830,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _2c9ecef2,
    name: "settings"
  }, {
    path: "/verify-email",
    component: _99d3a3d2,
    name: "verify-email"
  }, {
    path: "/gift-cards/redeem",
    component: _28f698f4,
    name: "gift-cards-redeem"
  }, {
    path: "/gift-cards/thank-you",
    component: _4852d0c2,
    name: "gift-cards-thank-you"
  }, {
    path: "/cart/:id",
    component: _55190dcc,
    name: "cart-id"
  }, {
    path: "/orders/:id",
    component: _35d98bc2,
    name: "orders-id"
  }, {
    path: "/",
    component: _26c9a7da,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
